@import "variables";

$btn-secondary-bg: $gray-200;

@import "../../node_modules/bootstrap/scss/bootstrap";

@import "bootswatch";

body,
html,
#root {
    height: 100%;
    min-height: 100vh;
}

#root {
    display: flex;
    flex-direction: column;
}

.center {
    display: flex;
    justify-content: center;
    align-items: center;
}

.gap {
    gap: 1rem;
}

.text-bold {
    font-weight: bold;
}

.flex-1 {
    flex: 1;
}

$snippet-bg: #f8f9fa;

.snippet {
    background-color: $snippet-bg;
    border: 1px solid #e9ecef;
    border-radius: 0.25rem;
    padding: 1rem;
    margin-bottom: 1rem;

    button {
        background: $snippet-bg;
    }
}

.font-small {
    font-size: 0.8rem;
}

.dropdown-menu-right {
    right: 0;
    left: auto !important;
}

.plan-card {
    display: flex;
    flex-direction: column;
    align-items: center;

    width: 18rem;
    padding: 1.5rem;
    border: 1px solid #ccc;
    border-radius: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

}

.plan-card p {
    font-size: smaller;
}

.plan-card .features {
    margin: 1rem 0;
}

.price {
    margin: 2rem 0;
    font-size: large;
}

.price .integer {
    font-size: xx-large;
}

.form-check-input {
    cursor: pointer;
}

.form-control-color {
    height: 2.5rem;
}

.options {
    overflow: hidden;
    transition: all 1s;
}

.pointer {
    cursor: pointer;
}

.help-card {
    width: 25rem;
    z-index: 20000;
}

/////////
/// RC-slider
/////////

.rc-slider {
    margin: 0 10px;
}

.rc-slider-track {
    background-color: $primary !important;
}

.rc-slider-handle {
    border: solid 2px $primary !important;
}

.rc-slider-handle:focus,
.rc-slider-handle:hover,
.rc-slider-handle:active {
    border-color: $primary !important;
    box-shadow: 0 0 5px $primary !important;
}


////////// 


@include media-breakpoint-down(lg) {
    .plan-card {
        width: 100%;
    }
}

@include media-breakpoint-down(md) {
    .help-card {
        width: 100vw;
    }
}